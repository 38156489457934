import { QuestionMarkSharp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Button } from "@mui/material";
import AssessmentItem from "../components/AssessmentItem";
import axios from "axios";

function PersonalityAssessment() {
  const { keycloak } = useKeycloak();
  const [assessment, setAssessment] = useState({ items: [] });
  const [answers, setAnswers] = useState({});

  const nextItems = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/assessment/disc/next")
      .then((response) => {
        setAssessment(response.data);
      });
  };

  useEffect(() => {
    setAnswers({});
  }, [assessment]);

  const answered = (itemid, value) => {
    var l = {};
    l[itemid] = value;
    l = { ...answers, ...l };
    setAnswers(l);
    console.log("New answer", Object.keys(l).length);
  };

  useEffect(() => {
    nextItems();
  }, []);
  return (
    <>
      <Typography variant="h5" color="primary" marginTop={3}>
        Answer these questions
      </Typography>
      {assessment.items.map((s, index) => (
        <AssessmentItem
          item={s}
          key={s.id}
          onAnswered={(v) => {
            answered(s.id, v);
          }}
        ></AssessmentItem>
      ))}
      {Object.keys(answers).length >= 5 && (
        <Button variant="contained" onClick={nextItems}>
          Next
        </Button>
      )}
    </>
  );
}

export default PersonalityAssessment;
