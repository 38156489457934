import { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import SimulationModal from "../components/SimulationModal";
import SimulationChat from "../components/SimulationChat";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { Help } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Fade } from "@mui/material";
import { SimulationContext } from "../context/SimulationContext";
import { useContext } from "react";

function Simulation() {
  const { chatid } = useParams();
  const [modal, setModal] = useState(false);
  const { keycloak } = useKeycloak();
  const { history, loadHistory, setHasNewMessage } =
    useContext(SimulationContext);
  const [go, setGo] = useState(0);

  useEffect(() => {
    loadHistory(chatid);
    setModal(true);
  }, []);

  if (go) {
    return <Navigate to={"/new/sim/" + chatid + "/rating"}></Navigate>;
  }

  return (
    <Box padding={3}>
      <SimulationModal history={history} open={modal} setOpen={setModal} />
      <Fade in={history.loaded}>
        <Box>
          {history.loaded && (
            <Typography variant="h5" component="h2" color="primary">
              {history.subject_content.title} with{" "}
              {history.persona_content.title}{" "}
              <Help
                onClick={() => {
                  setModal(true);
                }}
              ></Help>
            </Typography>
          )}

          <SimulationChat
            history={history}
            setHasNewMessage={setHasNewMessage}
          ></SimulationChat>
          <Box sx={{ width: "100%" }} padding={3} paddingLeft={0}>
            <Button
              style={{ float: "right" }}
              onClick={() => {
                setModal(true);
              }}
            >
              Instructions
            </Button>
            <Button
              variant="contained"
              color="warning"
              style={{ float: "right" }}
              onClick={() => {
                setGo(1);
              }}
            >
              End Conversation
            </Button>{" "}
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}

export default Simulation;
