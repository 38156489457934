import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";

const PrivateRoute = ({ children }) => {
 const { keycloak } = useKeycloak();

 const isLoggedIn = keycloak.authenticated;

 useEffect(() => {
    if (!keycloak.authenticated) {
   }
 }, [])

 return isLoggedIn ? children : null;
};

export default PrivateRoute;