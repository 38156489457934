import { Typography, Box, Grid, ListItem } from "@mui/material";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { SimulationContext } from "../context/SimulationContext";
import { Button } from "@mui/material";
import Feedback from "../components/Feedback";
import { Navigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import RateSimulation from "../components/RateSimulation";
import axios from "axios";
import { List } from "@mui/material";
function SimulationInsights() {
  const { chatid } = useParams();
  const { history, loadHistory } = useContext(SimulationContext);
  const [go, setGo] = useState(0);
  const [feedback, setFeedback] = useState(0);

  function get_feedback(chatid) {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/api/simulation/" +
          chatid +
          "/feedback"
      )
      .then((response) => {
        setFeedback(response.data);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    loadHistory(chatid);
    get_feedback(chatid);
  }, []);

  if (go) {
    return <Navigate to={"/new/sim/" + chatid + "/insights-rating"}></Navigate>;
  }

  return (
    <Box padding={3}>
      <Typography variant="h4" component="h2" color="primary" mb={3}>
        {" "}
        FlyingPenguins Insights
      </Typography>

      {feedback == 0 && (
        <Box marginTop={3} sx={{ minHeight: "500px" }}>
          <Typography variant="azh6">Processing Insights</Typography>
          <CircularProgress></CircularProgress>
        </Box>
      )}

      {feedback.improvement_opportunities !== undefined && (
        <Box sx={{ minHeight: "500px" }}>
          <Typography variant="h5" color="primary">
            Positive Aspects
          </Typography>
          <Typography>
            <List sx={{ display: "list-item" }}>
              {feedback.positive_aspects.map((f, index) => (
                <ListItem key={index}>{f}</ListItem>
              ))}
            </List>
            <Typography variant="h5" color="primary">
              Opportunities for Improvement
            </Typography>
            <List sx={{ display: "list-item" }}>
              {feedback.improvement_opportunities.map((f, index) => (
                <ListItem key={index}>{f}</ListItem>
              ))}
            </List>
          </Typography>
        </Box>
      )}

      <Box sx={{ width: "100%" }}>
        <Button
          variant="contained"
          sx={{ float: "right" }}
          onClick={() => {
            setGo(1);
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default SimulationInsights;
