import { useKeycloak } from "@react-keycloak/web";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Paper } from "@mui/material";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from "@mui/material";

const SimulationList = () => {
  const { keycloak } = useKeycloak();
  const [simulationList, setSimulationList] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/simulations", {
        headers: { Authorization: "Bearer " + keycloak.token },
      })
      .then((response) => {
        setSimulationList(response.data.items);
        console.log(response.data);
      });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Subject</TableCell>
            <TableCell align="right">Persona</TableCell>
            <TableCell align="right">Interactions</TableCell>
            <TableCell align="right">Go</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {simulationList.map((s, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {s.humandate}
              </TableCell>
              <TableCell align="right">{s.title}</TableCell>
              <TableCell align="right">{s.persona_name}</TableCell>
              <TableCell align="right">{s.count}</TableCell>
              <TableCell align="right">
                <Button component={Link} to={"/new/sim/" + s.chatid}>
                  Go to simulation
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  //return (<ul>
  //   {simulationList.map((s, index)=>
  //       <li key={index}>{s.created_at} <Link to={"/simulation/" + s.chatid}>{s.persona} - {s.subject }</Link></li>
  //   )}
};

export default SimulationList;
