import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  Checkbox,
  TableRow,
  Button,
  Box,
} from "@mui/material";

function UserInsights() {
  const { userid } = useParams();
  const [user, setUser] = useState(0);
  const [insight, setInsight] = useState(0);
  const [simulation_list, setSimulationList] = useState([]);

  function check(event, simid) {
    if (event.target.checked && !simulation_list.includes(simid)) {
      simulation_list.push(simid);
    } else if (!event.target.checked && simulation_list.includes(simid)) {
      const position = simulation_list.indexOf(simid);
      simulation_list.splice(position, 1);
    }
    console.log("simulation list", simulation_list);
  }

  function getInsight() {
    setInsight(1);
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/users/" + userid + "/insights",
        { simulations: simulation_list }
      )
      .then((response) => {
        setInsight(response.data);
      });
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/users/" + userid + "/details")
      .then((response) => {
        setUser(response.data);
        var l = [];
        for (var i in response.data.simulations) {
          if (response.data.simulations[i].count > 4) {
            l.push(response.data.simulations[i].id);
          }
        }
        setSimulationList(l);
      });
  }, []);

  if (user !== 0)
    return (
      <>
        <Typography variant="h3" color="primary">
          {user.user.first_name} {user.user.last_name}
        </Typography>
        <Table>
          <TableHead>
            <TableCell>Select</TableCell>
            <TableCell>When</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Persona</TableCell>
            <TableCell>Nb Of Interaction</TableCell>
            <TableCell>Chat Rating</TableCell>
            <TableCell>Insight Rating</TableCell>
          </TableHead>
          <TableBody>
            {user.simulations.map((s, index) => (
              <TableRow key={index}>
                <TableCell>
                  {s.count > 4 && (
                    <Checkbox
                      defaultChecked
                      onChange={(event) => {
                        check(event, s.id);
                      }}
                    ></Checkbox>
                  )}
                </TableCell>
                <TableCell>{s.humandate}</TableCell>
                <TableCell>{s.title}</TableCell>
                <TableCell>{s.persona_name}</TableCell>
                <TableCell>{s.count}</TableCell>
                <TableCell>{s.chatRating > 0 && s.chatRating}</TableCell>
                <TableCell>{s.insightRating > 0 && s.insightRating}</TableCell>
                <TableCell>
                  <Button component={Link} to={"/new/sim/" + s.chatid}>
                    Go to simulation
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="h6" color="primary" marginTop={3}>
          Get Insights
        </Typography>
        <Typography padding={2}>
          Once you've selected the simulations you think are relevant for
          generating insights, please click here.
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            getInsight();
          }}
        >
          Call Insight Engine
        </Button>
        <Box marginTop={3}>
          {insight == 1 && <CircularProgress />}
          {insight.insight !== undefined && (
            <Box>
              <Typography variant="h5" color="primary">
                Analysis Overview
              </Typography>
              <Typography>{insight.insight.analysis_overview}</Typography>

              <Typography variant="h5" color="primary">
                Strenghts to improve
              </Typography>
              <Typography>
                <ul>
                  {insight.insight.reinforce_strengths.map((s, index) => (
                    <li key={index}>
                      <b>{s.strength}</b> : {s.improve}
                    </li>
                  ))}
                </ul>
              </Typography>

              <Typography variant="h5" color="primary">
                Opportunities for improvement
              </Typography>
              <Typography>
                <ul>
                  {insight.insight.opportunities_for_improvements.map(
                    (s, index) => (
                      <li key={index}>
                        <b>{s.area}</b> : {s.content}
                      </li>
                    )
                  )}
                </ul>
              </Typography>

              <Typography variant="h5" color="primary">
                Conversation Starters
              </Typography>
              <Typography>
                <ul>
                  {insight.insight.conversation_starters.map((s, index) => (
                    <li key={index}>{s}</li>
                  ))}
                </ul>
              </Typography>

              <Typography variant="h5" color="primary">
                Calls to action
              </Typography>
              <Typography>
                <ul>
                  {insight.insight.calls_to_action.map((s, index) => (
                    <li key={index}>{s}</li>
                  ))}
                </ul>
              </Typography>
            </Box>
          )}
        </Box>
      </>
    );
  else return <>loading ...</>;
}
export default UserInsights;
