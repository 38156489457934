import { Modal, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import { List, ListItem } from "@mui/material";
import { Fade } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  minHeight: "70%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  backgroundColor: "white",
  //  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
};

function SimulationModal({ history, open = false, setOpen }) {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box style={style}>
        {history.loaded == false && (
          <Box padding={3} style={{ left: "50%" }}>
            <CircularProgress></CircularProgress>
          </Box>
        )}

        <Fade in={history.loaded} sx={{ flexGrow: 1, display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            {history.loaded == true && (
              <Box
                padding={3}
                sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
              >
                <Box>
                  <Typography variant="h5" component="h2" color="primary">
                    {history.subject_content.title} with{" "}
                    {history.persona_content.title}
                  </Typography>
                  <Box marginTop={3}>
                    <Typography mt={2}>
                      <b>Goal : </b> {history.subject_content.goal} <br />
                    </Typography>
                    <Typography mt={2}>
                      <b>Scenario : </b>{" "}
                      {history.subject_content.learner_instructions}
                    </Typography>
                    <Typography mt={2}>
                      <b>Persona : </b>{" "}
                      {history.persona_content.learner_instructions}
                    </Typography>
                    <Typography mt={2}>
                      <b>Steps to follow : </b>
                    </Typography>
                  </Box>
                  <Typography>
                    <Box paddingLeft={2}>
                      <List sx={{ listStyleType: "disc" }}>
                        {history.subject_content.instruction_sections.map(
                          (i, index) => (
                            <ListItem sx={{ display: "list-item" }}>
                              {i.description}
                            </ListItem>
                          )
                        )}
                      </List>
                    </Box>
                  </Typography>
                  <Box marginTop={3}>
                    <Typography mt={2}>
                      <b>
                        A minimum of 5 interactions - you and{" "}
                        {history.persona_content.title} saying something - is
                        required to get interesting insights
                      </b>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ flexGrow: 1 }}> </Box>
                <Box padding={3} paddingLeft={0} sx={{ width: "100%" }}>
                  <Button
                    sx={{ float: "right" }}
                    variant="contained"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Go to conversation
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Fade>
      </Box>
    </Modal>
  );
}

export default SimulationModal;
