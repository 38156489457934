import sam from "../images/sam.png";
import donna from "../images/donna.png";
import isabel from "../images/isabel.png";
import carlos from "../images/carlos.png";

const PersonaImages = {
  donna: donna,
  carlos: carlos,
  sam: sam,
  isabel: isabel,
};

export default PersonaImages;
