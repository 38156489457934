import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useEffect, useContext } from "react";
import { MainContext } from "../context/MainContext";
import { Box, Typography, Button } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import { ContactsOutlined } from "@mui/icons-material";

function InvitationAccept() {
  const { invitationCode } = useParams();
  const [invitationDetails, setInvitationDetails] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const { keycloak } = useKeycloak();
  const { reloadMe } = useContext(MainContext);

  function acceptDenyInvitation(accept) {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/api/invitations/accept/" +
          invitationCode,
        {
          headers: { Authorization: "Bearer " + keycloak.token },
          accept: accept,
        }
      )
      .then((response) => {
        reloadMe();
        setRedirect(true);
      });
  }

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/api/invitations/detail/" +
          invitationCode,
        { Authorization: "Bearer " + keycloak.token }
      )
      .then((response) => {
        setInvitationDetails(response.data);
        console.log(response.data);
      });
  }, []);

  if (!keycloak.authenticated) {
    return (
      <Box>
        <Typography variant="h6" color="primary">
          Invitation
        </Typography>
        To accept this invitation you need to register/login{" "}
        <Button
          onClick={() => {
            keycloak.login();
          }}
          variant="contained"
        >
          Login
        </Button>
      </Box>
    );
  }

  if (redirect) {
    return <Navigate to="/home"></Navigate>;
  }

  if (invitationDetails.invitation.status > 0) {
    return (
      <Box padding={3}>
        <Typography variant="h6" color="primary">
          Invitation already accepted
        </Typography>
        <Button variant="contained" component={Link} to="/home">
          Back to homepage
        </Button>
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <Typography variant="h6" color="primary">
        Invitation to join flying penguins
      </Typography>
      <Box padding={3}>
        <Typography variant="body2">
          Invitation originally sent to :{" "}
          <strong>{invitationDetails.invitation.email}</strong>
          <br></br>
          Sent by : {invitationDetails.creator.first_name}{" "}
          {invitationDetails.creator.last_name} <br></br>
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          acceptDenyInvitation(true);
        }}
      >
        Accept Invitation
      </Button>
    </Box>
  );
}

export default InvitationAccept;
