import { createContext, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import axios from "axios";

export const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  const { keycloak, initialized } = useKeycloak();

  function getMe() {
    axios.defaults.headers.common["Authorization"] = initialized
      ? `Bearer ${keycloak.token}`
      : undefined;
    if (initialized && keycloak.token) {
      axios
        .get(process.env.REACT_APP_API_URL + "/api/users/me")
        .then((response) => {
          console.log(response.data)
          setUserInfo(() => {
            return response.data.user;
          });
        });
    }
  }

  function reloadMe() {
    getMe()
  }
  useEffect(() => {
    getMe()
  },
    [initialized])

  return (
    <MainContext.Provider
      value={{
        userInfo,
        setUserInfo,
        reloadMe
      }}>
      {children}
    </MainContext.Provider>
  );
}