import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { MainContext } from "../context/MainContext";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Input,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Paper, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Box,
  Select,
  MenuItem,
} from "@mui/material";

function Invitation() {
  const [invitations, setInvitations] = useState([]);
  const [email, setEmail] = useState("");
  const [inviteSent, setInviteSent] = useState(0);
  const [inviteCoach, setInviteCoach] = useState(false);
  const { userInfo } = useContext(MainContext);
  const { keycloak } = useKeycloak();

  function getStatus(i) {
    switch (i.status) {
      case 0:
        return <b>pending</b>;
        break;
      case 1:
        return <b>accepted</b>;
        break;
      case 2:
        return <b>rejected</b>;
        break;
    }
  }
  function get_invitations() {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/invitations", {
        headers: { Authorization: "Bearer " + keycloak.token },
      })
      .then((response) => {
        setInvitations(response.data.items);
        console.log(response.data);
      });
  }

  function send_invitation() {
    axios
      .post(process.env.REACT_APP_API_URL + "/api/invitations", {
        headers: { Authorization: "Bearer " + keycloak.token },
        email: email,
        inviteCoach: inviteCoach == "true",
      })
      .then((response) => {
        setEmail("");
        setInviteSent(1);
        setTimeout(() => {
          setInviteSent(0);
        }, 3000);
        get_invitations();
      });
  }

  useEffect(() => {
    get_invitations();
  }, []);

  return (
    <>
      <Typography color="text.primary" variant="h4" component="h2" padding={2}>
        Manage Users
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardContent sx={{ width: "100%" }}>
              <Typography variant="h5" component="h2" color="primary">
                Create invitation
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Grid
                  container
                  sx={{ width: "100%", display: "flex" }}
                  alignItems="center"
                >
                  <Grid item xs={3} sx={{ flexGrow: 1 }} alignItems="center">
                    <Typography variant="h6">Emails to invite</Typography>
                    <Typography variant="body2">
                      You can add more than one email separated by commas
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      name="email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      value={email}
                      sx={{ width: "100%" }}
                    ></Input>
                    {inviteSent > 0 && (
                      <Box>
                        Email(s) sent ! You should see the invite appearing on
                        top of the list
                      </Box>
                    )}
                  </Grid>
                  {userInfo.isAdmin && (
                    <>
                      {" "}
                      Invite as:
                      <Grid item xs={2}>
                        <Select
                          value={inviteCoach}
                          onChange={(event) => {
                            setInviteCoach(event.target.value);
                          }}
                        >
                          <MenuItem value="false">User</MenuItem>
                          <MenuItem value="true">Coach</MenuItem>
                        </Select>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </CardContent>
            <CardActionArea>
              <CardActions>
                <Button onClick={send_invitation}>Send invitation</Button>
              </CardActions>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" component="h2" color="primary">
                Existing invitation
              </Typography>
              <Box padding={2}>
                <TableContainer component={Paper} elevation={2}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ fontWeight: 700 }}>
                        <TableCell>Date Invited</TableCell>
                        <TableCell align="right">Code</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">First Name</TableCell>
                        <TableCell align="right">Last Name</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">Nb Simulations</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invitations.map((i, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            tooltip={i.invitation.created_at}
                          >
                            {i.humandate}
                          </TableCell>
                          <TableCell align="right">
                            {i.invitation.code}
                          </TableCell>
                          <TableCell align="right">
                            {i.invitation.email}
                          </TableCell>
                          <TableCell align="right">
                            {getStatus(i.invitation)}
                          </TableCell>
                          <TableCell align="right">
                            {i.user && i.user.first_name}
                          </TableCell>
                          <TableCell align="right">
                            {i.user && i.user.last_name}
                          </TableCell>
                          <TableCell align="right">
                            {i.invitation.inviteCoach && "Coach"}
                            {!i.invitation.inviteCoach && "User"}
                          </TableCell>

                          <TableCell align="right">
                            {i.count > 0 && i.count}
                          </TableCell>

                          <TableCell align="right">
                            {i.user && (
                              <Button
                                component={Link}
                                to={"/user/" + i.user.id + "/insights"}
                              >
                                Go to details
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Invitation;
