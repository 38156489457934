import { createContext } from "react";
import { useState } from "react";
import axios from "axios";

export const SimulationContext = createContext();

function SimulationContextProvider({ children }) {
  const [history, setHistory] = useState({ loaded: false, history: [] });
  const [hasNewMessage, setHasNewMessage] = useState(false);

  function loadHistory(chatid, force = false) {
    if (
      history.loaded == true &&
      history.chatid == chatid &&
      force == false &&
      hasNewMessage == false
    ) {
      return;
    }
    if (history.chatid != chatid) {
      setHistory({ loaded: false, history: [] });
    }

    axios
      .get(
        process.env.REACT_APP_API_URL + "/api/simulation/" + chatid + "/history"
      )
      .then((response) => {
        setHasNewMessage(false);
        setHistory({ ...history, ...response.data, loaded: true });
      })
      .catch(() => {});
  }

  return (
    <SimulationContext.Provider
      value={{
        history,
        setHistory,
        loadHistory,
        hasNewMessage,
        setHasNewMessage,
      }}
    >
      {children}
    </SimulationContext.Provider>
  );
}

export default SimulationContextProvider;
