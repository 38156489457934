import { useParams } from "react-router-dom";
import { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import SimulationList from "../components/SimulationList";
import PersonIcon from "@mui/icons-material/Person";
import SubjectIcon from "@mui/icons-material/Subject";
import { List, ListItem } from "@mui/material";
import PersonaImages from "../helpers/PersonaImages";

import {
  Grid,
  Box,
  Card,
  CardContent,
  CardActionArea,
  CardHeader,
  CardActions,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Select, MenuItem, Button, Typography } from "@mui/material";

const SimulationOption = () => {
  const [persona, setPersona] = useState("carlos");
  const [subject, setSubject] = useState("receiving_feedback");
  const [chatUrl, setChatUrl] = useState(false);
  const { keycloak } = useKeycloak();
  const [selectedPersona, setSelectedPersona] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");

  const [learnerInstructions, setLearnerInstructions] = useState({
    personas: [],
    subjects: [],
  });

  function launchSimulation() {
    if (selectedPersona === "" || selectedSubject === "") {
      return;
    }
    axios
      .post(process.env.REACT_APP_API_URL + "/api/simulation/", {
        persona: selectedPersona,
        subject: selectedSubject,
      })
      .then((response) => {
        const simulation = response.data;
        setChatUrl("/new/sim/" + simulation.chatid);
      })
      .catch(() => {});

    axios;
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/simulations/personas_subjects")
      .then((response) => {
        setLearnerInstructions(response.data);
      });
  }, []);

  return (
    <>
      {chatUrl && <Navigate to={chatUrl}></Navigate>}
      <Typography color="text.primary" variant="h4" component="h2" padding={2}>
        Select a Scenario and a Persona for your Simulation
      </Typography>

      <Typography variant="h6" paddingTop={3}>
        1/2. Select the Scenario you will practice during your Simulation
      </Typography>
      {learnerInstructions.subjects.length == 0 && (
        <>
          <Typography>loading ...</Typography>
        </>
      )}
      <Grid container spacing={4} paddingTop={3}>
        {learnerInstructions.subjects.map((s, index) => (
          <>
            {s.visible == true && (
              <Grid item xs={3}>
                <Card
                  elevation={3}
                  onClick={() => {
                    setSelectedSubject(s.code);
                    var element = document.getElementById("to_persona");
                    element.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h5" component="h2" color="primary">
                        <SubjectIcon></SubjectIcon> {s.name}
                      </Typography>
                      <Typography variant="body2">
                        <b>Goal : </b> {s.goal} <br />
                        <b>Scenario : </b> {s.learner_instructions}
                        <br />
                        <b>Steps to follow : </b>
                      </Typography>
                      <Typography variant="body2">
                        <Box paddingLeft={2}>
                          <List sx={{ listStyleType: "disc" }}>
                            {s.instruction_sections.map((i, index) => (
                              <ListItem
                                sx={{ display: "list-item" }}
                                padding={0}
                              >
                                {i.description}
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Typography>
                      <Typography variant="body2">
                        At least 5 interactions are required.
                      </Typography>
                    </CardContent>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <CardActionArea>
                      {s.activated && (
                        <Radio checked={selectedSubject === s.code}></Radio>
                      )}
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>
            )}
          </>
        ))}
      </Grid>
      <Box paddingTop={3}>
        <hr></hr>
      </Box>
      <Typography variant="h6" paddingTop={3} id="to_persona">
        2/2. Select a Persona
      </Typography>
      <Typography variant="body1">
        Select a conversation partner for your Simulation from one of the four
        Personas below.
      </Typography>
      {learnerInstructions.personas.length == 0 && (
        <Typography>loading ...</Typography>
      )}
      <Grid container spacing={4} paddingTop={3}>
        {learnerInstructions.personas.map((s, index) => (
          <Grid item xs={3}>
            <Card
              elevation={3}
              onClick={() => {
                setSelectedPersona(s.code);
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <CardContent>
                  <Box>
                    <img
                      src={PersonaImages[s.name.toLowerCase()]}
                      width="50%"
                      marginRight={3}
                    ></img>

                    <Typography variant="h5" component="h2" color="primary">
                      {s.name}
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    {s.learner_instructions}
                  </Typography>
                  <Typography variant="body2">
                    <Box paddingLeft={2}>
                      <List sx={{ listStyleType: "disc" }}>
                        {s.instruction_sections.map((i, index) => (
                          <ListItem sx={{ display: "list-item" }}>
                            {i.description}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Typography>
                </CardContent>
                <Box sx={{ flexGrow: 1 }}></Box>
                <CardActionArea>
                  <Radio checked={selectedPersona === s.code}></Radio>
                </CardActionArea>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box paddingTop={3}>
        <Button variant="contained" onClick={launchSimulation}>
          Start Simulation
        </Button>
        {(selectedPersona === "" || selectedSubject === "") && (
          <Typography component="b">
            {" "}
            You need to select both a Persona and a Scenario before starting the
            simulation
          </Typography>
        )}
      </Box>
    </>
  );
};

export default SimulationOption;
