import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import reptileImage from "../images/donna.png";
import SubjectIcon from "@mui/icons-material/Subject";
import { List, ListItem } from "@mui/material";
import { CardActionArea, Radio } from "@mui/material";

export default function MediaCard({ s, selected, doSelect }) {
  if (s.name === undefined) {
    return <Card></Card>;
  } else
    return (
      <Card elevation={3} sx={{ width: "350px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <CardContent>
            <Typography variant="h5" component="h2" color="primary">
              <SubjectIcon></SubjectIcon> {s.name}
            </Typography>
            <Typography variant="body2">{s.learner_instructions}</Typography>
            <Typography variant="body2">
              <Box paddingLeft={2}>
                <List sx={{ listStyleType: "disc" }}>
                  {s.instruction_sections.map((i, index) => (
                    <ListItem sx={{ display: "list-item" }}>
                      {i.description}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Typography>
            <Typography variant="body2">
              At least 5 interactions are required.
            </Typography>
          </CardContent>
          <Box sx={{ flexGrow: 1 }}></Box>
          <CardActionArea
            onClick={() => {
              doSelect(s.code);
            }}
          >
            {s.activated && <Radio checked={selected === s.code}></Radio>}
          </CardActionArea>
        </Box>
      </Card>
    );
}
