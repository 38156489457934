import { TextField, Button, Typography, Box } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Avatar } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { MainContext } from "../context/MainContext";
import { Input } from "@mui/material";

function UserProfile() {
  const [uploadData, setUploadData] = useState(0);
  const { userInfo, reloadMe } = useContext(MainContext);
  const [userData, setUserData] = useState({
    first_name: userInfo.first_name,
    last_name: userInfo.last_name,
    companyLogo: userInfo.companyLogo,
    companyName: userInfo.companyName,
    coachBio: userInfo.companyBio,
  });

  useEffect(() => {
    reloadMe();
  }, []);

  useEffect(() => {
    setUserData({
      ...userData,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      companyLogo: userInfo.companyLogo,
      avatar: userInfo.avatar,
      companyName: userInfo.companyName,
      coachBio: userInfo.coachBio,
    });
  }, [userInfo]);

  const [uploadCompanyData, setUploadCompanyData] = useState(0);
  const [profileImage, setProfileImage] = useState(0);
  const [companyImage, setCompanyImage] = useState(0);
  const { keycloak } = useKeycloak();

  function saveUserData() {
    axios
      .post(process.env.REACT_APP_API_URL + "/api/users/me", userData, {
        headers: { Authorization: "Bearer " + keycloak.token },
      })
      .then((response) => {
        reloadMe();
      });
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setUploadData(formData);
  };

  const handleCompanyUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setUploadCompanyData(formData);
  };

  function changeField(fieldname, value) {
    var l = {};
    l[fieldname] = value;

    setUserData({ ...userData, ...l });
  }

  function uploadCompanyImage() {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/user/mycompany",
        uploadCompanyData,
        {
          headers: { Authorization: "Bearer " + keycloak.token },
        }
      )
      .then((response) => {
        changeField("companyLogo", response.data.content);
      });
  }

  function uploadImage() {
    axios
      .post(process.env.REACT_APP_API_URL + "/api/user/myavatar", uploadData, {
        headers: { Authorization: "Bearer " + keycloak.token },
      })
      .then((response) => {
        changeField("avatar", response.data.content);
      });
  }

  return (
    <>
      <Box marginTop={3} sx={{ width: "100%" }}>
        <Typography variant="h5" color="primary">
          Personal Profile
        </Typography>
        {userInfo.avatar !== undefined && (
          <Avatar
            src={userData.avatar}
            sx={{ width: "150px", height: "150px" }}
          ></Avatar>
        )}
        <Box marginTop={3}>
          Upload image : <br></br>
          <form>
            <TextField type="file" onChange={handleFileUpload}></TextField>{" "}
            <Button variant="contained" onClick={uploadImage}>
              Upload image
            </Button>
            <br></br>
          </form>
        </Box>
        <Typography variant="h6">First Name</Typography>
        <Input
          name="first_name"
          sx={{ width: "400px" }}
          value={userData.first_name}
          onChange={(event) => {
            changeField("first_name", event.target.value);
          }}
        ></Input>

        <Typography variant="h6">Last Name</Typography>
        <Input
          name="last_name"
          sx={{ width: "400px" }}
          value={userData.last_name}
          onChange={(event) => {
            changeField("last_name", event.target.value);
          }}
        ></Input>
        {userInfo.isCoach && (
          <>
            <Typography variant="h5" color="primary" marginTop={3}>
              Coach Profile
            </Typography>
            <Typography variant="h6">Your Bio</Typography>
            <TextField
              value={userData.coachBio}
              variant="outlined"
              rows={8}
              multiline={true}
              onChange={(event) => {
                changeField("coachBio", event.target.value);
              }}
              sx={{ width: "400px" }}
            />
            <Typography variant="h6" marginTop={3}>
              Your company name
            </Typography>
            <Input
              name="last_name"
              sx={{ width: "400px" }}
              value={userData.companyName}
              onChange={(event) => {
                changeField("companyName", event.target.value);
              }}
            ></Input>
            <Typography variant="h6" marginTop={3}>
              Your company logo
            </Typography>

            <Avatar
              src={userData.companyLogo}
              sx={{ width: "200px", height: "100px" }}
              variant="rounded"
            ></Avatar>
            <Box marginTop={3}>
              Upload image : <br></br>
              <form>
                <TextField
                  type="file"
                  onChange={handleCompanyUpload}
                ></TextField>{" "}
                <Button variant="contained" onClick={uploadCompanyImage}>
                  Upload image
                </Button>
                <br></br>
              </form>
            </Box>
          </>
        )}
      </Box>
      <Button variant="contained" onClick={saveUserData}>
        Save
      </Button>
    </>
  );
}

export default UserProfile;
