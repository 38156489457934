import { useKeycloak } from "@react-keycloak/web";
import { DeepChat } from "deep-chat-react";
import { Box, Typography } from "@mui/material";
import { SimulationContext } from "../context/SimulationContext";
import { useContext, useState, useEffect } from "react";

function SimulationChat({ history, setHasNewMessage }) {
  const { keycloak } = useKeycloak();
  const [localhistory, setLocalHistory] = useState([]);

  useEffect(() => {
    if (history.loaded && localhistory.length < history.history.length) {
      setLocalHistory(history.history);
    }
  }, [history]);

  if (!history.loaded) return <></>;

  return (
    <>
      <Box sx={{ width: "100%", display: "flex" }}>
        <DeepChat
          id="maindeepchat"
          demo={true}
          speechToText='{"webSpeech": {"language": "en-US"}, "submitAfterSilence": 200}'
          style={{
            borderRadius: "10px",
            width: "100%",
            height: "600px",
          }}
          connect={{
            url: process.env.REACT_APP_API_URL + "/api/chat/message",
            additionalBodyProps: {
              token: keycloak.token,
              chatid: history.chatid,
            },
          }}
          onMessage={(message) => {
            if (message.isHistory || message.isInitial) {
              return;
            }
            localhistory.push(message.message);
            setLocalHistory(localhistory);
          }}
          textInput={{ placeholder: { text: "..." } }}
          history={localhistory}
        />
      </Box>
    </>
  );
}

export default SimulationChat;
