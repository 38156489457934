import { LoginButton } from "../components/LoginButton";
import { MainContext } from "../context/MainContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  Grid,
  CardActions,
  CardActionArea,
  Typography,
  Button,
  Box,
  Avatar,
} from "@mui/material";

export const UserHome = () => {
  const { userInfo } = useContext(MainContext);

  return (
    <>
      {userInfo.first_name && (
        <>
          <Typography
            color="text.primary"
            variant="h4"
            component="h2"
            padding={2}
          >
            Welcome {userInfo.first_name} {userInfo.last_name}
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={4} style={{ display: "flex" }}>
              <Card elevation={3}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <CardContent style={{ flexGrow: 1 }}>
                    <Box padding={1}>
                      <Typography component="h2" color="primary" variant="h5">
                        Flying Penguins Simulations
                      </Typography>
                      <Typography variant="body2">
                        FlyingPenguins simulations offer a unique opportunity to
                        practice essential collaboration skills, such as giving
                        and receiving feedback or showing empathy, through
                        realistic chat-based conversations. Each simulation
                        lasts between 3 to 7 minutes, depending on your level of
                        engagement. The more you engage, the more you’ll learn.
                      </Typography>
                      <Typography variant="body2">
                        We recommend acting as you would in a professional
                        setting when interacting with a colleague or manager.
                        Afterward, you'll receive personalized feedback that
                        highlights your strengths and areas for improvement. The
                        results of the simulations and the feedback will be
                        shared with your coach to inform your development plan.
                        To begin, simply choose a persona, select a topic to
                        practice, and click 'Start Simulation.'
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActionArea>
                    <CardActions>
                      <Button
                        component={Link}
                        to="/simulation/options"
                        variant="contained"
                      >
                        Go to Practice
                      </Button>
                      <Button component={Link} to="/simulation/past">
                        Go to Past Simulations
                      </Button>
                    </CardActions>
                  </CardActionArea>
                </Box>
              </Card>
            </Grid>
            {userInfo.isCoach && (
              <Grid item xs={4} style={{ display: "flex" }}>
                <Card elevation={3} padding={3}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <CardContent style={{ flexGrow: 1 }}>
                      <Box padding={1}>
                        <Typography component="h2" variant="h5" color="primary">
                          Manage your users
                        </Typography>
                        <Typography variant="body2">
                          Flying Penguins allows you to invite and understand
                          where your users are in their training journey. The
                          coaching area will give you an overview of all of them
                          and our insight engine will give you valuable
                          informations about the user behavior in a simple and
                          actionable way.
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActionArea>
                      <CardActions>
                        <Button component={Link} to="/invitation">
                          Users and invitations
                        </Button>
                      </CardActions>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>
            )}
            {userInfo.coach !== null && (
              <Grid item xs={4} style={{ display: "flex" }}>
                <Card elevation={3} padding={3}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <CardContent style={{ flexGrow: 1 }}>
                      <Box padding={1} alignContent="center">
                        <Typography component="h2" variant="h5" color="primary">
                          Your Coach
                        </Typography>
                        <Typography variant="h6">
                          {userInfo.coach.first_name} {userInfo.coach.last_name}
                        </Typography>
                        <Box alignContent={"center"} padding={3}>
                          <Avatar
                            sx={{ width: "150px", height: "150px" }}
                            src={userInfo.coach.avatar}
                          ></Avatar>
                        </Box>
                        <Typography component="h2" variant="h6" color="primary">
                          About {userInfo.coach.first_name}
                        </Typography>
                        <Typography variant="body2">
                          {userInfo.coach.coachBio}
                        </Typography>
                        {userInfo.coach.companyName && (
                          <Box marginTop={3}>
                            <Typography
                              component="h2"
                              variant="h6"
                              color="primary"
                            >
                              From : {userInfo.coach.companyName}
                            </Typography>
                            <Box alignContent={"center"} padding={3}>
                              <Avatar
                                variant="rounded"
                                sx={{ width: "200px", height: "100px" }}
                                src={userInfo.coach.companyLogo}
                              ></Avatar>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                    <CardActionArea>
                      <CardActions></CardActions>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default UserHome;
