import { AppBar } from "@mui/material";
import { Box, Toolbar, IconButton, Button } from "@mui/material";
import { Typography } from "@mui/material";
import { MenuOpen } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { AccountCircle } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { MainContext } from "../context/MainContext";
import { useContext } from "react";
import beta from "../images/beta.svg";

function NavigationBar() {
  const [anchorEl, setAnchorEl] = useState(<></>);
  const { keycloak } = useKeycloak();
  const { userInfo } = useContext(MainContext);
  const menuId = "primary-search-account-menu";
  const isMenuOpen = Boolean(anchorEl);

  function handleMenuClose() {}
  function handleProfileMenuOpen() {}

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={5}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            component={Link}
            to="/home"
          >
            <FlightTakeoffIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Flying Penguins{" "}
            <img
              src={beta}
              height="30px"
              style={{ filter: "invert(100%)" }}
            ></img>
          </Typography>
          {keycloak.authenticated && (
            <Box sx={{ display: { xs: "none", sm: "block", color: "white" } }}>
              <Button
                component={Link}
                to="/home"
                variant="contained"
                sx={{ marginRight: "10px" }}
              >
                Home
              </Button>
              {userInfo.isCoach && (
                <Button
                  component={Link}
                  variant="contained"
                  to="/invitation"
                  sx={{ marginRight: "10px" }}
                >
                  Users
                </Button>
              )}
              <Button
                component={Link}
                variant="contained"
                to="/simulation/options"
              >
                Practice
              </Button>
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
                component={Link}
                to="/user/profile"
              >
                <AccountCircle />
              </IconButton>
              <Button
                variant="contained"
                onClick={() => {
                  keycloak.logout();
                }}
              >
                Logout
              </Button>
            </Box>
          )}
          {!keycloak.authenticated && (
            <Button
              variant="contained"
              onClick={() => {
                keycloak.login();
              }}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavigationBar;
