import { Box } from "@mui/material";

function Footer() {
  return (
    <Box marginTop={6} sx={{ width: "100hh" }} padding={6}>
      &#169; FlyingPenguins 2024
    </Box>
  );
}

export default Footer;
